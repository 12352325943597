import { GatsbyPageProps, Token } from '@/types';
import { ArticleList } from '../../articles';
import Xxx20WeekMaChartTemplate from '@/templates/crypto/xxx-20-week-moving-average-chart';

export default function Bitcoin20WeekMaChart({ location }: GatsbyPageProps) {
  return (
    <Xxx20WeekMaChartTemplate
      coinAbbreviation="BTC"
      coinFullName="Bitcoin"
      token={Token.BTCUSDT}
      location={location}
      articleId={ArticleList.BITCOIN_20_WEEK_MOVING_AVERAGE_CHART}
    />
  );
}
